<template>
    <CCard class="zw-page">
        <CCardBody>
            <b-row v-if="searchData">
                <b-col cols="12" v-for="(data,key) in searchData"
                       :key="key"
                       v-if="data.length && !key.includes('_found')"
                >

                    <font-awesome-icon class="mr-1" :icon="getIconByKey(key)"/>
                    <strong>{{ $t('common.search_category.' + key) }}
                        <span v-if="searchData[key + '_found']>0">({{
                                searchData[key + '_found']
                            }})</span></strong>

                    <template v-if="key=='offerings'">
                        <b-table-simple striped>
                            <b-thead head-variant="info">
                                <b-th>{{ $t('common.search.label.id') }}</b-th>
                                <b-th>{{ $t('common.search.label.number') }}</b-th>
                            </b-thead>
                            <tbody>
                            <b-tr v-for="(item,index) in data"
                                  :key="'item_'+index"
                            >
                                <b-td>{{ item.id }}</b-td>
                                <b-td>
                                    <a href="#"
                                       @click="openSearchItem(key, item)"
                                    >{{ item.number }}</a>
                                </b-td>
                            </b-tr>
                            </tbody>
                        </b-table-simple>
                    </template>
                    <template v-if="key=='customers'">
                        <b-table-simple striped>
                            <b-thead head-variant="info">
                                <b-th>{{ $t('common.search.label.id') }}</b-th>
                                <b-th>{{ $t('common.search.label.company') }}</b-th>
                            </b-thead>
                            <tbody>
                            <b-tr v-for="(item,index) in data"
                                  :key="'item_'+index"
                            >
                                <b-td>{{ item.id }}</b-td>
                                <b-td>
                                    <a href="#"
                                       @click="openSearchItem(key, item)"
                                    >{{ item.agenturname }}</a>
                                </b-td>
                            </b-tr>
                            </tbody>
                        </b-table-simple>
                    </template>
                    <template v-if="key=='persons'">
                        <b-table-simple striped>
                            <b-thead head-variant="info">
                                <b-th>{{ $t('common.search.label.id') }}</b-th>
                                <b-th>{{ $t('common.search.label.vorname') }}</b-th>
                            </b-thead>
                            <tbody>
                            <b-tr v-for="(item,index) in data"
                                  :key="'item_'+index"
                            >
                                <b-td>{{ item.id }}</b-td>
                                <b-td>
                                    <a href="#"
                                       @click="openSearchItem(key, item)"
                                    >{{ item.vorname }}</a>
                                </b-td>
                            </b-tr>
                            </tbody>
                        </b-table-simple>
                    </template>
                    <template v-if="key=='articles'">
                        <b-table-simple striped>
                            <b-thead head-variant="info">
                                <b-th>{{ $t('common.search.label.id') }}</b-th>
                                <b-th>{{ $t('common.search.label.sku') }}</b-th>
                            </b-thead>
                            <tbody>
                            <b-tr v-for="(item,index) in data"
                                  :key="'item_'+index"
                            >
                                <b-td>{{ item.id }}</b-td>
                                <b-td>
                                    <a href="#"
                                       @click="openSearchItem(key, item)"
                                    >{{ item.sku }}</a>
                                </b-td>
                            </b-tr>
                            </tbody>
                        </b-table-simple>
                    </template>
                    <template v-if="key=='invoices'">
                        <b-table-simple striped>
                            <b-thead head-variant="info">
                                <b-th>{{ $t('common.search.label.id') }}</b-th>
                                <b-th>{{ $t('common.search.label.number') }}</b-th>
                            </b-thead>
                            <tbody>
                            <b-tr v-for="(item,index) in data"
                                  :key="'item_'+index"
                            >
                                <b-td>{{ item.id }}</b-td>
                                <b-td>
                                    <a href="#"
                                       @click="openSearchItem(key, item)"
                                    >{{ item.number }}</a>
                                </b-td>
                            </b-tr>
                            </tbody>
                        </b-table-simple>
                    </template>
                    <template v-if="key=='contracts'">
                        <b-table-simple striped>
                            <b-thead head-variant="info">
                                <b-th>{{ $t('common.search.label.id') }}</b-th>
                                <b-th>{{ $t('common.search.label.file') }}</b-th>
                            </b-thead>
                            <tbody>
                            <b-tr v-for="(item,index) in data"
                                  :key="'item_'+index"
                            >
                                <b-td>{{ item.id }}</b-td>
                                <b-td>
                                    <a href="#"
                                       @click="openSearchItem(key, item)"
                                    >{{ item.file }}</a>
                                </b-td>
                            </b-tr>
                            </tbody>
                        </b-table-simple>
                    </template>
                    <template v-if="key=='incoming_invoices'">
                        <b-table-simple striped>
                            <b-thead head-variant="info">
                                <b-th>{{ $t('common.search.label.id') }}</b-th>
                                <b-th>{{ $t('common.search.label.number') }}</b-th>
                            </b-thead>
                            <tbody>
                            <b-tr v-for="(item,index) in data"
                                  :key="'item_'+index"
                            >
                                <b-td>{{ item.id }}</b-td>
                                <b-td>
                                    <a href="#"
                                       @click="openSearchItem(key, item)"
                                    >{{ (item.rechnungsnummer ? ' ' + item.rechnungsnummer : '') }}</a>
                                </b-td>
                            </b-tr>
                            </tbody>
                        </b-table-simple>
                    </template>
                </b-col>
            </b-row>
        </CCardBody>
    </CCard>
</template>

<script>
export default {
    name: 'Search',
    data() {
        return {
            form: {},
            searchData: null,
        }
    },
    mounted() {
        let query = this.$route.params.query;

        let params = {search: query}
        this.axios.post(window.apiUrl + '/common-data/search', params)
            .then(response => {
                this.searchData = response.data
            })
    },
    methods: {
        getIconByKey(key) {
            const map = {
                'offerings': 'chart-bar',
                'customers': 'briefcase',
                'persons': 'users',
                'articles': 'barcode',
                'invoices': 'file-invoice',
                'incoming_invoices': 'file',
            }

            return map[key]
        },
        openSearchItem(key, item) {
            if (key == 'offerings') {
                this.$root.$children[0].openModal('offering-modal', {id: item.id})
            } else if (key == 'customers') {
                this.$root.$children[0].openModal('customer-modal', {customerId: item.id})
            } else if (key == 'persons') {
                this.$root.$children[0].openModal('customer-modal', {customerId: item.kunden_Id})
            } else if (key == 'articles') {
                this.$root.$children[0].openModal('article-modal', {id: item.id})
            } else if (key == 'invoices') {
                this.$root.openDocument(item.number)
            } else if (key == 'contracts') {
                this.$root.openDocument(item.file, 'customer_contracts')
            } else if (key == 'incoming_invoices') {
                this.$root.$children[0].openModal('incoming-invoices-modal', {
                        id: item.id,
                    }
                )
            }
        },
    }
}
</script>